import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import HeadingM from "../shared/HeadingM"
import Map from "../../components/shared/about/Map"

const Detail = () => {
  const data = [
    { label: "店舗名", value: "Do CAFE" },
    { label: "営業時間", value: "月～金 ／ 11:00〜17:00\n土曜日 ／ 10:00～17:00" },
    { label: "定休日", value: "日曜日" },
    { label: "住所", value: "〒378-0016 群馬県沼田市清水町４２０８−１" },
    { label: "連絡先", value: "080-3442-9975" },
  ]

  return (
    <div id="info" className="pt-12">
      <HeadingM title="店舗詳細" target="doCafe" />
      <div className="flex flex-col-reverse justify-between mt-12 md:mt-16 md:flex-row md:space-x-8">
        <ul className="mt-5 md:mt-0">
          {data.map(item => (
            <div key={item.label}>
              <li
                className="relative grid grid-cols-4 py-4 border-dashed md:py-7 border-Brown"
                key={item.label}
              >
                <div className="col-span-1">{item.label}</div>
                <div className="col-span-3 whitespace-pre-wrap">
                  {item.value}
                  {item.label === "住所" && (
                    <a
                      href="https://maps.app.goo.gl/QKPCb52wErBPHShP8"
                      className="block text-xs text-blue-500 underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Googleマップで開く
                    </a>
                  )}
                </div>
              </li>
              <div className="border-dash" />
            </div>
          ))}
        </ul>
        <StaticImage
          src="../../images/do-cafe/building.jpg"
          alt="cafe"
          className="m-auto md:w-1/2 rounded-3xl h-96 md:ml-5"
        />
      </div>
      <Map lat={36.6442409647128} lng={139.03558865370775} />
    </div>
  )
}

export default Detail
