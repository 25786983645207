import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import HeadingM from "../shared/HeadingM"

const Menu = () => (
  <div id="menu" className="">
    <HeadingM title="メニュー" target="doCafe" />
    <div className="max-w-2xl mx-auto mt-12 md:mt-20">
      <div className="items-center grid-cols-3 gap-8 text-center md:text-left md:grid">
        <StaticImage
          src="../../images/do-cafe/crepe.jpg"
          alt="crepe"
          className="rounded-2xl"
          width={250}
          height={250}
        />
        <div className="col-span-2">
          <div className="mt-5 mb-5 text-xl font-bold md:mt-0 font-kiwiMaru">
            クレープ
          </div>
          <div className="text-left">
            <div className="mt-3">
              定番から季節限定クレープまで、見た目にも味にもこだわったさまざまな種類のクレープをお楽しみください。
            </div>
          </div>
        </div>
      </div>
      <div className="my-10 border-dash" />
      <div className="items-center grid-cols-3 gap-8 text-center md:text-left md:grid">
        <StaticImage
          src="../../images/do-cafe/cafe-drink.jpg"
          alt="crepe"
          className="rounded-2xl"
          width={250}
          height={250}
        />
        <div className="col-span-2">
          <div className="mt-5 mb-5 text-xl font-bold md:mt-0 font-kiwiMaru">
            ドリンク
          </div>
          <div className="text-left">
            <div className="mt-3">
              コーヒー（HOT・ICE）、ソフトドリンクを各種ご用意しています。
            </div>
          </div>
        </div>
      </div>
      <div className="my-10 border-dash" />
      <div className="items-center grid-cols-3 gap-8 text-center md:text-left md:grid">
        <StaticImage
          src="../../images/do-cafe/warabi-drink.jpg"
          alt="crepe"
          className="rounded-2xl"
          width={250}
          height={250}
        />
        <div className="col-span-2">
          <div className="mt-5 mb-5 text-xl font-bold md:mt-0 font-kiwiMaru">
            わらび餅ドリンク
          </div>
          <div className="text-left">
            <div>● 抹茶・黒糖ミルク・ミルクティー</div>
            <div className="mt-3">
              老舗和菓子店「かねもと」さんとのコラボドリンク。こだわりのドリンクにかねもとさんのわらび餅をたっぷりと合わせて、「つるっと」した新食感が楽しめるドリンクです。
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 md:mt-20">
        ※メニューは季節ごとに変わりますので、最新情報は
        <a
          href="https://www.instagram.com/docafe0501/"
          className="font-bold text-doCafe"
        >
          Do CAFEのインスタグラム
        </a>
        をご確認ください。
      </div>
    </div>
  </div>
)

export default Menu
