import React from "react"

const Concept = () => {
  return (
    <div id="about" className="mt-12 text-center font-kiwiMaru">
      <div
        className={`md:p-8 mb-10 text-lg leading-relaxed text-center bg-repeat-round md:text-xl md:leading-relaxed`}
      >
        Do CAFEのモットーは「やりたい」「働きたい！」「楽しい！！」が形になる。
        <br />
        あなたのやりたいを叶え、一緒に働く仲間がいる。
        <br className="hidden md:block" />
        「挑戦・やる・できる」３つの【Do】を掲げる就労支援 cafe【Do CAFE】
      </div>
    </div>
  )
}

export default Concept
