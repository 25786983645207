import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFax, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { faLine, faInstagram } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <footer className="relative mt-20">
      <div
        className={`h-4 mt-32 bg-repeat-x md:h-8 bg-doCafeWaveSp md:bg-doCafeWave`}
      ></div>
      <div className="bg-repeat-round bg-doCafeImage">
        <div className="mx-auto max-w-[960px] pt-10 px-4">
          <div className="justify-center md:flex">
            <div className="">
              <StaticImage
                src="../../images/do-cafe/logo.png"
                alt="logo"
                layout="fixed"
                width={100}
                className="flex-shrink-0 mx-auto"
              />
            </div>
            <div className="mt-8 text-center md:text-left md:ml-20 md:mt-0">
              <div className="mb-4 text-xl font-kiwiMaru">Do CAFE</div>
              <div>
                〒378-0016 <br className="md:hidden" />
                群馬県利根郡沼田市清水町４２０８−１
              </div>
              <div>TEL: 080-5739-9072</div>
            </div>
          </div>
          <div className="flex justify-center gap-5 pb-16 mt-7">
            <a href="https://www.instagram.com/docafe0501/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} size="2x" color="dimgray" />
            </a>
          </div>
          <div className="absolute -translate-x-1/2 bottom-4 left-1/2">
            © {new Date().getFullYear()} NOONE Inc.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
