import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import HeadingM from "../shared/HeadingM"
import PartnersBase from "../shared/top/Partners"

const query = graphql`
  query {
    do: file(relativePath: { eq: "do/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const Partners = () => {
  const data = useStaticQuery(query)
  const items = [
  ]
  return (
    <div className="mt-24">
      <HeadingM title="提携事業所" target="doCafe" />
      <PartnersBase items={items} />
    </div>
  )
}

export default Partners
